
form[name=agency-switch-form] {
  display: flex;
}
form[name=agency-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=agency-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=agency-switch-form] > div > div {
  flex-grow:1;
}
form[name=agency-switch-form] > button {
  margin-left:10px;
}


.localizedText .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedText textarea {
  border-top:0px;
}
.localizedText.has-success .nav-tabs {
  border-color: #1ab394;
}
.localizedText.has-error .nav-tabs {
  border-color: #ed5565;
}
.localizedText.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedText.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}


.fa-check.active[data-v-f5040084], .fa-user-circle-o.active[data-v-f5040084] {
  color:green;
}
.fa-check.disabled[data-v-f5040084], .fa-user-circle-o.disabled[data-v-f5040084] {
  color:red;
}





/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/



.button.payment {
  width: 100%;
}



.review-booking hr {
  border-top: 2px solid rgba(0,0,0,.3);
  margin-left: -20px;
  margin-right: -20px;
}
.review-booking td.header {
  white-space: nowrap;
}



.fa-check.active[data-v-73e4c980], .fa-user-circle-o.active[data-v-73e4c980] {
  color:green;
}
.fa-check.disabled[data-v-73e4c980], .fa-user-circle-o.disabled[data-v-73e4c980] {
  color:red;
}




.fa-check.active[data-v-91502448], .fa-user-circle-o.active[data-v-91502448] {
  color:green;
}
.fa-check.disabled[data-v-91502448], .fa-user-circle-o.disabled[data-v-91502448] {
  color:red;
}



